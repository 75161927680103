import classNames from "classnames";
import moment from "moment";
import React from "react";

import WorkerItem from "components/UI/_TODO/WorkOrMaterialsModals/components/WorkersList/WorkerItem";

import PriorityRange from "../PriorityRange/PriorityRange";
import TaskPerson from "../TasksModalV2/ui/TaskPerson/TaskPerson";

import { TASK_PRIORITY } from "../../constants";
import { ITaskHistory } from "../../model/types";

import getShortFullName from "utils/formatters/getShortFullName";

import styles from "./TaskHistory.module.scss";

interface IProps {
  item: ITaskHistory;
}

const getStatus = (item: ITaskHistory) => {
  if (item.history_type === "создание") {
    return "Задача создана";
  }

  if (item.details?.some((el) => el.field === "status" && el.new_value === "transferred")) {
    return "Задача передана";
  }

  if (item.details?.some((el) => el.field === "executor")) {
    return "Задача передана";
  }

  if (item.details?.some((el) => el.field === "status" && el.new_value === "in_work")) {
    return "Взята в работу";
  }

  if (item.details?.some((el) => el.field === "status" && el.new_value === "completed")) {
    return "Отправлена на проверку";
  }

  if (item.details?.some((el) => el.field === "status" && el.new_value === "to_do")) {
    return "Отправлена на доработку";
  }

  if (item.details?.some((el) => el.field === "status" && el.new_value === "closed")) {
    return "Задача закрыта";
  }

  return "Задача изменена";
};

const getRowName = (field: ITaskHistory["details"][0]["field"]) => {
  if (field === "deadline") {
    return "Дата сдачи";
  }

  if (field === "description") {
    return "Описание";
  }

  if (field === "executor") {
    return "Исполнитель";
  }

  if (field === "viewer") {
    return "Контроллер";
  }

  if (field === "name") {
    return "Название";
  }

  if (field === "priority") {
    return "Приоритет";
  }

  return "";
};

const getRowContent = (entry: ITaskHistory["details"][0]) => {
  const { field } = entry;
  if (field === "deadline") {
    return (
      <>
        <div className={classNames(styles.deadline, styles.old)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6 2.5C6 2.22386 6.22386 2 6.5 2H7.5C7.77614 2 8 2.22386 8 2.5V4.00001H16V2.5C16 2.22386 16.2239 2 16.5 2H17.5C17.7761 2 18 2.22386 18 2.5V4.00001H18.5C20.433 4.00001 22 5.56701 22 7.50001V18.5C22 20.433 20.433 22 18.5 22H5.5C3.567 22 2 20.433 2 18.5V7.50001C2 5.56701 3.567 4.00001 5.5 4.00001H6V2.5ZM4 18.5V9H20V18.5C20 19.3284 19.3284 20 18.5 20H5.5C4.67157 20 4 19.3284 4 18.5ZM13.5 14C13.2239 14 13 14.2239 13 14.5V17.5C13 17.7761 13.2239 18 13.5 18H17.5C17.7761 18 18 17.7761 18 17.5V14.5C18 14.2239 17.7761 14 17.5 14H13.5Z"
              fill="#4FB1EB"
            />
          </svg>
          {moment(entry.old_value).format("DD/MM/YY")}
        </div>

        <div className={classNames(styles.deadline)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6 2.5C6 2.22386 6.22386 2 6.5 2H7.5C7.77614 2 8 2.22386 8 2.5V4.00001H16V2.5C16 2.22386 16.2239 2 16.5 2H17.5C17.7761 2 18 2.22386 18 2.5V4.00001H18.5C20.433 4.00001 22 5.56701 22 7.50001V18.5C22 20.433 20.433 22 18.5 22H5.5C3.567 22 2 20.433 2 18.5V7.50001C2 5.56701 3.567 4.00001 5.5 4.00001H6V2.5ZM4 18.5V9H20V18.5C20 19.3284 19.3284 20 18.5 20H5.5C4.67157 20 4 19.3284 4 18.5ZM13.5 14C13.2239 14 13 14.2239 13 14.5V17.5C13 17.7761 13.2239 18 13.5 18H17.5C17.7761 18 18 17.7761 18 17.5V14.5C18 14.2239 17.7761 14 17.5 14H13.5Z"
              fill="#4FB1EB"
            />
          </svg>
          {moment(entry.new_value).format("DD/MM/YY")}
        </div>
      </>
    );
  }

  if (field === "executor") {
    return (
      <>
        <div className={classNames(styles.old, styles.worker)}>
          <TaskPerson person={entry.old_value as any} />
        </div>

        <div className={classNames(styles.worker)}>
          <TaskPerson person={entry.new_value as any} />
        </div>
      </>
    );
  }

  if (field === "priority") {
    return (
      <>
        <div className={classNames(styles.old, styles.work4er)}>
          <PriorityRange label="" value={entry.old_value as any} disabled />
        </div>

        <div className={classNames(styles.worke4r)}>
          <PriorityRange label="" value={entry.new_value as any} disabled />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={classNames(styles.old, styles.work4er)}>{entry.old_value as any}</div>
      <div className={classNames(styles.worke4r)}>{entry.new_value as any}</div>
    </>
  );
};

const statusColors: Record<string, string> = {
  "Задача изменена": "#A26DD0",
  "Задача закрыта": "#47D6D1",
  any: "#4FB1EB",
};

const TaskHistoryItem: React.FC<IProps> = ({ item }) => {
  const type = getStatus(item);
  return (
    <div className={styles.container}>
      <div className={styles.type} style={{ background: statusColors[type] ?? statusColors.any }}>
        {type}
      </div>
      <div className={styles.date} style={{ background: (statusColors as any)[type] ?? statusColors.any }}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.06401 1.5C4.06401 1.22386 4.28787 1 4.56401 1H5.13894C5.41508 1 5.63894 1.22386 5.63894 1.5V2.18493H10.3637V1.5C10.3637 1.22386 10.5876 1 10.8637 1H11.4386C11.7148 1 11.9386 1.22386 11.9386 1.5V2.18493H12.2026C13.749 2.18493 15.0026 3.43854 15.0026 4.98493V12.1997C15.0026 13.7461 13.749 14.9997 12.2026 14.9997H3.8C2.25361 14.9997 1 13.7461 1 12.1997V4.98494C1 3.43854 2.25361 2.18493 3.8 2.18493H4.06401V1.5ZM2.6 12.1997V5.86212H13.4026V12.1997C13.4026 12.8625 12.8653 13.3997 12.2026 13.3997H3.8C3.13726 13.3997 2.6 12.8625 2.6 12.1997ZM9.19035 9.33667C8.91421 9.33667 8.69035 9.56053 8.69035 9.83667V11.6146C8.69035 11.8907 8.91421 12.1146 9.19035 12.1146H11.6355C11.9117 12.1146 12.1355 11.8907 12.1355 11.6146V9.83667C12.1355 9.56053 11.9117 9.33667 11.6355 9.33667H9.19035Z"
            fill="white"
          />
        </svg>
        {moment(item.history_date).format("DD/MM/YYYY")} в {moment(item.history_date).format("HH:mm")}
      </div>
      <div className={styles.authorRow}>
        <div className={styles.descr}>
          {type === "Задача создана" && "Создал:"}
          {type === "Задача изменена" && "Изменил:"}
          {type !== "Задача изменена" && type !== "Задача создана" && "Сотрудник:"}
        </div>
        <div className={styles.value}>
          {type === "Задача создана" && <TaskPerson person={item.creator as any} />}
          {type !== "Задача создана" && <TaskPerson person={item.change_by as any} />}
        </div>
      </div>
      {item.details
        ?.filter((el) => el.field !== "status")
        .map((el, i) => (
          <div key={el.field + i + item.history_date} className={styles.list}>
            <div className={styles.authorRow}>
              <div className={styles.descr}>{getRowName(el.field)} (подано):</div>
              <div className={styles.descr}>{getRowName(el.field)} (изменено):</div>
            </div>
            <div className={styles.authorRow}>{getRowContent(el)}</div>
          </div>
        ))}
    </div>
  );
};

export default TaskHistoryItem;
