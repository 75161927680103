import React, { ReactNode } from "react";

import MatchingIcon from "../../../../_LEGACY/UI/MatchingIcon/MatchingIcon";
import AddRelationToProduct from "components/UI/_TODO/AddRelationToProduct";

import ProductInfoModal from "entities/ProductInfoModal/ProductInfoModal";

import { IExpenditure } from "types/interfaces/Expenditure";

import { getMatchingStatus } from "./utils";

import styles from "./MatchingModal.module.scss";

export interface IMatchingInfo {
  estimate_expenditure: IExpenditure;
  created_from_estimate: boolean;
  is_removed: boolean;
  is_default?: boolean;
}

interface IProps {
  info: IMatchingInfo;
  objectId: string;
  updateProducts: () => void;
  readOnly: boolean;
  type: string;
  renderCustomMatchingModal?: (isOpen: boolean, onClose: () => void) => ReactNode;
  customOpenBehavior?: (info: IMatchingInfo) => "info" | "infoWithEdit" | "matching";
}

const MatchingModal: React.FC<IProps> = ({
  info,
  readOnly,
  objectId,
  type,
  updateProducts,
  renderCustomMatchingModal,
  customOpenBehavior,
}) => {
  //кто это писал как это вообще разделять между собой
  const [matchingModalOpen, setMatchingModalOpen] = React.useState(false);
  const [matchingOpen, setMatchingOpen] = React.useState(false);
  const [chosenMatchingProduct, setChosenMatchingProduct] = React.useState<IMatchingInfo | null>(null);

  const openMatchingModal = (info: IMatchingInfo) => {
    setMatchingModalOpen(true);
    setChosenMatchingProduct(info);
  };

  const closeMatchingModal = () => {
    setMatchingModalOpen(false);
  };

  const openMatching = (info: IMatchingInfo) => {
    setMatchingModalOpen(false);
    setMatchingOpen(true);
    setChosenMatchingProduct(info);
  };

  const closeMatching = () => {
    setMatchingOpen(false);
  };

  const onClickByMatchingIcon = () => {
    if (customOpenBehavior) {
      if (customOpenBehavior(info) === "info") {
        openMatchingModal(info);
      }
      if (customOpenBehavior(info) === "infoWithEdit") {
        openMatchingModal(info);
        //TODO edit = true
      }
      if (customOpenBehavior(info) === "matching") {
        openMatching(info);
      }
      return;
    }
    if (info.estimate_expenditure) {
      openMatchingModal(info);
    } else if (!readOnly) {
      openMatching(info);
    }
  };

  const onOpenEditMatching = () => {
    if (!chosenMatchingProduct) return;
    openMatching(chosenMatchingProduct);
  };

  const canEdit =
    (!info.created_from_estimate && !readOnly) ||
    info.estimate_expenditure.is_default === true ||
    info.estimate_expenditure.is_default === "True";

  return (
    <div>
      {!readOnly && matchingOpen && !renderCustomMatchingModal && (
        <AddRelationToProduct /* @ts-ignore */
          idAddRelation={info}
          type={type} /* @ts-ignore */
          chosenMatchingProduct={chosenMatchingProduct}
          updateProducts={updateProducts}
          handleClose={closeMatching}
          objectId={objectId}
          editable={true}
        />
      )}
      <ProductInfoModal
        isOpen={matchingModalOpen}
        onClose={closeMatchingModal}
        onOpenEditing={onOpenEditMatching}
        canEdit={canEdit}
        section={chosenMatchingProduct?.estimate_expenditure?.section}
        number={chosenMatchingProduct?.estimate_expenditure?.number}
        justification={chosenMatchingProduct?.estimate_expenditure?.justification}
        expenditureName={chosenMatchingProduct?.estimate_expenditure?.name}
        price={chosenMatchingProduct?.estimate_expenditure?.price}
        count={chosenMatchingProduct?.estimate_expenditure?.count}
        isLoading={false}
      />

      <MatchingIcon
        className={styles.matchingIcon}
        onClick={onClickByMatchingIcon}
        status={getMatchingStatus(info)}
        disabled={info.is_removed}
      />
      {renderCustomMatchingModal && renderCustomMatchingModal(matchingOpen, () => setMatchingOpen(false))}
    </div>
  );
};

export default MatchingModal;
